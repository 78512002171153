<template>
  <header :class="['sticky-header', { 'detached': isHeaderDetached }]">
    <div class="logo-container">
      <ResponsiveLogo id="responsive-logo" />
    </div>
<!--    <button-->
<!--        v-show="$route.name === 'SpecifiedDestinationOverview'"-->
<!--        class="edit-destination-btn"-->
<!--        @click="switchToDestinationEditionMode"-->
<!--    >-->
<!--      Éditer ce projet-->
<!--    </button>-->

<!--    <img v-if="!isAuthenticated" :src="userImage" alt="user logo" class="user-logo" @click="openLoginModal">-->
    <button v-if="!isAuthenticated" @click="openLoginModal" class="connection-btn">
      Se connecter
    </button>
    <router-link v-else :to="{name: 'UserProfile'}" class="profile-redirect-link">
      <img :src="userImage" alt="user logo" class="user-logo">
    </router-link>

    <LoginModal v-if="isLoginModalOpened" @close="closeLoginModal" is-open/>
  </header>
</template>

<script>
import ResponsiveLogo from "@/components/Base/ResponsiveLogo.vue";
import LoginModal from "@/components/LoginModal.vue";
import { mapGetters } from 'vuex';

export default {
  name: 'HeaderSticky',
  data() {
    return {
      userImage: require('@/assets/user.png'),
      isLoginModalOpened: false,
    };
  },
  components: {
    LoginModal,
    ResponsiveLogo,
  },
  methods: {
    switchToDestinationEditionMode() {
      // if user not logged, show login modal
      // else
      // call API to create entry in database for user & destination
      // redirect on EditDestination page :
      // this.$router.push({ name: 'EditDestination', params: { destinationName: this.$route.params.destinationName } });
    },
    openLoginModal() {
      this.isLoginModalOpened = true;
    },
    closeLoginModal() {
      this.isLoginModalOpened = false;
    },
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'user']),
  },
}
</script>

<style scoped>
.sticky-header {
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  position: sticky;
  padding: 10px;
  background-color: #fff;
  transition: top 0.3s ease;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1);
}

.logo-container {
  display: flex;
  justify-content: flex-start;
  flex: 1;
}

.user-logo {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.profile-redirect-link {
  height: 35px;
}

.edit-destination-btn {
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid var(--primaryColor);
  color: var(--primaryColor);
  padding: 5px;
  border-radius: 5px;
  font-size: 10px;
  background-color: white;
  font-weight: bolder;
}

.connection-btn {
  background-color: white;
  color: var(--primaryColor);
  border-radius: 5px;
  padding: 8px 17px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--primaryColor);
}

.connection-btn:hover {
  background: linear-gradient(to right, var(--primaryColor), var(--hoverColor));
  color: #fff;
  border: 1px solid var(--primaryColor);
}

@media (min-width: 768px) {
  .sticky-header {
    justify-content: center;
  }

  .user-logo {
    width: 40px;
    height: 40px;
  }

  .profile-redirect-link {
    height: 40px;
  }

  .connection-btn {
    padding: 10px 20px;
    font-size: 17px;
  }
}
</style>
