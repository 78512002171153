<template>
  <ModalComponent :is-open="true" @close="closeModal">
    <div class="login-container">
      <div class="slider-wrapper">
        <div class="slider-container" :class="{ 'slide-left': emailSubmitted }">
          <div class="slider-panel email-form">
            <h2>Se connecter ou créer un compte</h2>
            <label for="email">Adresse e-mail</label>
            <input
                id="email"
                v-model="email"
                type="email"
                placeholder="Veuillez saisir votre adresse e-mail"
                @input="this.errorMsg = null"
            />
            <ButtonWithGradient
                class="auth-form-btn"
                @click="submitEmail"
                message="Continuer avec une adresse e-mail"
            />
          </div>
          <div v-if="emailExists" class="slider-panel password-form">
            <h2>Heureux de vous revoir !</h2>
            <label for="password">Mot de passe</label>
            <input
                id="password"
                v-model="password"
                type="password"
                placeholder="Veuillez saisir votre mot de passe"
                @input="this.errorMsg = null"
            />
            <ButtonWithGradient
                class="auth-form-btn"
                @click="login"
                message="Se connecter"
            />
          </div>
          <div v-else class="slider-panel signup-form">
            <h2>Créer un mot de passe</h2>
            <p>Il doit comporter au moins 10 caractères, dont 1 majuscule, 1 minuscule et 1 chiffre.</p>
            <label for="password">Mot de passe</label>
            <input
                id="password"
                v-model="password"
                type="password"
                placeholder="Saisissez un mot de passe"
                @input="this.errorMsg = null"
            />
            <label for="passwordConfirmation">Confirmez le mot de passe</label>
            <input
                id="passwordConfirmation"
                v-model="passwordConfirmation"
                type="password"
                placeholder="Confirmez le mot de passe"
                @input="this.errorMsg = null"
            />
            <ButtonWithGradient
                class="auth-form-btn"
                @click="signup"
                message="Créer un compte"
            />
          </div>
        </div>
        <span v-if="errorMsg" class="error-message">{{ this.errorMsg }}</span>
      </div>
      <div class="social-login">
        <div class="divider">
          <span>ou choisissez l'une de ces options</span>
        </div>
        <div class="social-icons">
          <GoogleLogin :callback="onGoogleLoginResponse">
            <svg
                class="social-btn"
                viewBox="0 0 262 262"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid"
                aria-hidden="true"
                focusable="false"
                width="24"
                height="24"
                role="img"
            >
              <path
                  d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                  fill="#4285F4"
              ></path>
              <path
                  d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                  fill="#34A853"
              ></path>
              <path
                  d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                  fill="#FBBC05"
              ></path>
              <path
                  d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                  fill="#EB4335"
              ></path>
            </svg>
          </GoogleLogin>
        </div>
      </div>
    </div>
  </ModalComponent>
</template>

<script>
import ModalComponent from "@/components/Base/Modal.vue";
import { GoogleLogin } from "vue3-google-login";
import { mapActions } from 'vuex';
import smartVacancesApiClient from "@/SmartVacancesApiClient";
import ButtonWithGradient from "@/components/Base/ButtonWithGradient.vue";

export default {
  components: {ButtonWithGradient, GoogleLogin, ModalComponent },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      email: '',
      password: '',
      passwordConfirmation: '',
      emailSubmitted: false,
      emailExists: true,
      errorMsg: null,
    };
  },
  methods: {
    ...mapActions(['applyLogin']),
    async submitEmail() {
      try {
        const response = await smartVacancesApiClient.get('v1/users/' + this.email + '/exists');

        this.emailExists = response.data.data;
        if (this.email) {
          this.emailSubmitted = true;
        }
      } catch (error) {
        this.errorMsg = "Une erreur s'est produite";
      }
    },
    async login() {
      try {
        const response = await smartVacancesApiClient.post('v1/users/login', {
          email: this.email,
          password: this.password,
        });

        if (response.status === 200) {
          // Animation to show user is logged in
          this.handleLogin(response.data.data.token);
          this.closeModal();
        }

      } catch (error) {
        this.errorMsg = "Une erreur s'est produite lors de la connexion";
      }
    },
    async signup() {
      if (this.password !== this.passwordConfirmation) {
        this.errorMsg = 'Les mots de passe ne correspondent pas';

        return;
      }

      try {
        const response = await smartVacancesApiClient.post('v1/users', {
          email: this.email,
          password: this.password,
        });

        if (response.status === 201) {
          this.emailExists = true;
          this.emailSubmitted = true;

          this.handleLogin(response.data.data.token);
          this.closeModal();
        }
      } catch (error) {
        console.log(error);

        this.errorMsg = "Une erreur s'est produite lors de la création du compte";
      }
    },
    closeModal() {
      this.$emit('close');
    },
    onGoogleLoginResponse(response) {
      console.log(response);
      // Handle Google login response
      this.closeModal();
    },
    handleLogin(token) {
      this.applyLogin(token);
    }
  },
};
</script>

<style scoped>
.login-container {
}

h2 {
  font-size: 1.5em;
  margin-bottom: 1em;
}

label {
  display: block;
  margin-bottom: 0.5em;
}

input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.auth-form-btn {
  width: 100%;
  border-radius: 4px;
}

.slider-wrapper {
  overflow: hidden;
  width: 100%;
}

.slider-container {
  display: flex;
  width: 200%;
  transition: transform 0.4s ease-in-out;
  margin-bottom: 10px;
}

.slider-panel {
  width: 50%;
  padding: 0 10px;
}

.slide-left {
  transform: translateX(-50%);
}

.social-login {
  margin-top: 10px;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
}

.divider::before,
.divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #ccc;
}

.divider:not(:empty)::before {
  margin-right: 0.25em;
}

.divider:not(:empty)::after {
  margin-left: 0.25em;
}

.social-icons {
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
}

.social-btn {
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgrey;
}

.social-btn:hover {
  background-color: #f9f9f9;
}

@media (min-width: 768px) {
  button {
    font-size: 16px;
  }
}
</style>
