<template>
  <div class="faq-sidebar">
    <div class="categories">
      <button
          v-for="category in categories"
          :key="category.id"
          class="category-button"
          :class="{ active: currentCategory === category.id }"
          @click="selectCategory(category.id)"
      >
        {{ category.name }}
      </button>
    </div>
    <div class="content">
      <div
          v-for="item in filteredItems"
          :key="item.id"
          :class="{ 'faq-item': true, 'full-screen': item.isOpen && isMobile }"
      >
        <button class="question" @click="toggle(item.id)">
          {{ item.question }}
          <span class="icon">{{ item.isOpen ? '-' : '+' }}</span>
        </button>
        <div v-if="item.isOpen" class="answer">
          <div class="mobile-header" v-if="isMobile">
            <h5>{{ item.question }}</h5>
            <button class="close-button" @click="toggle(item.id)">×</button>
          </div>
          <p>{{ item.answer }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FaqSidebar',
  data() {
    return {
      currentCategory: null,
      isMobile: window.innerWidth <= 768,
      categories: [
        { id: 'generation', name: 'Génération de suggestions' },
        { id: 'specification', name: "Spécification d'une destination" },
        { id: 'modifications', name: 'Modifications de mon voyage' }
      ],
      faqItems: [
        {
          id: 1,
          category: 'generation',
          question: 'Comment faites-vous pour générer des suggestions de voyage ?',
          answer: "Nous utilisons une combinaison de données historiques, préférences utilisateur et algorithmes d'intelligence artificielle pour proposer des destinations qui correspondent à vos envies et besoins. Nous intégrerons le moindre critère (réalisable bien entendu) dans vos options de voyage : destination, transport, hébergement et planning.",
          isOpen: false
        },
        {
          id: 2,
          category: 'generation',
          question: 'Quels critères puis-je préciser pour affiner les suggestions ?',
          answer: "Vous pouvez spécifier des critères tels que le climat espéré, le budget, les types d'activités souhaitées, les préférences de transport, etc.. Nous prendrons en compte au mieux chacun de ces critères. Si vous avez un doute, n'hésitez pas à ouvrir la fenêtre d'aide sur la page de génération.",
          isOpen: false
        },
        {
          id: 3,
          category: 'specification',
          question: 'Comment puis-je découvrir les modalités de transport pour ma destination suggérée ?',
          answer: 'Une fois une sélection de 3 destinations proposées, vous pouvez cliquer sur celle qui vous enchante (ou affiner vos critères et relancer une sélection de 3 destinations. Une fois arrivé sur la page de votre destination choisie, vous aurez accès à tous les détails sur les options de transport disponibles, y compris les vols, les trains, ou les locations de voiture.',
          isOpen: false
        },
        {
          id: 4,
          category: 'specification',
          question: 'Comment découvrir mon planning généré ?',
          answer: "Une fois une sélection de 3 destinations proposées, vous pouvez cliquer sur celle qui vous enchante (ou affiner vos critères et relancer une sélection de 3 destinations. Une fois arrivé sur la page de votre destination choisie, vous aurez accès à tous les détails sur les activités disponible sur place. Vous serez également en mesure d'éditer ces informations à votre guise en illimité !",
          isOpen: false
        },
        {
          id: 5,
          category: 'specification',
          question: 'Mes critères ont-ils un impact sur toutes les spécifications de mon voyage ?',
          answer: 'Oui, les critères que vous fournissez influencent directement les suggestions de destinations, les modalités de transport, ainsi que les activités et hébergements proposés.',
          isOpen: false
        },
        {
          id: 6,
          category: 'modifications',
          question: 'Puis-je modifier les aspects de mon voyage si certains ne me conviennent pas ?',
          answer: "Absolument, vous pouvez personnaliser chaque aspect de votre voyage proposé, depuis le choix des dates, du transports, de l'hébergement et bien sûr du planning !",
          isOpen: false
        }
      ],
    };
  },
  computed: {
    filteredItems() {
      return this.faqItems.filter(item => item.category === this.currentCategory);
    }
  },
  methods: {
    toggle(id) {
      const item = this.faqItems.find(item => item.id === id);
      if (item) {
        item.isOpen = !item.isOpen;
      }
    },
    selectCategory(id) {
      this.currentCategory = id;
      if (this.isMobile) {
        this.faqItems.forEach(item => {
          if (item.category !== id) {
            item.isOpen = false;
          }
        });
      }
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth <= 768;
    });
    this.selectCategory('generation');
  },
};
</script>

<style scoped>
.faq-sidebar {
  display: flex;
  height: 100%;
}

.categories {
  flex: 1;
  background: #f5f5f5;
  padding: 20px;
}

.category-button {
  width: 100%;
  padding: 10px;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
}

.category-button.active {
  font-weight: bold;
}

.content {
  flex: 3;
  padding: 20px;
}

.question {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  border-bottom: 1px solid var(--primaryColor);
}

.answer {
  padding: 10px;
  text-align: left;
}

.icon {
  float: right;
}

.mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #f5f5f5;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.faq-item.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 10;
  overflow: auto;
  padding: 20px;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .faq-item.full-screen {
    position: static;
    height: auto;
    background: none;
    padding: 0;
    overflow: visible;
  }
}
</style>
