<template>
  <div>
    <div class="tab-header">
      <div
          v-for="(tab, index) in tabs"
          :key="index"
          :class="['tab', { active: activeTab === index }]"
          @click="selectTab(index)"
      >
        {{ tab.label }} ({{ tab.count }})
      </div>
    </div>
    <div class="tab-content">
      <slot :name="'tab-' + activeTab"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabSelector',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  methods: {
    selectTab(index) {
      this.activeTab = index;
    },
  },
};
</script>

<style scoped>
.tab-header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab {
  cursor: pointer;
  padding: 10px 20px;
  border: 1px solid var(--primaryColor);
  border-radius: 5px;
  margin: 0 5px;
}

.tab.active {
  border: none;
  background: linear-gradient(to bottom right, var(--hoverColor), var(--primaryColor));
  color: white;
}

.tab-content {
  padding: 20px;
  border: 1px solid var(--primaryColor);
  border-radius: 5px;
}
</style>
